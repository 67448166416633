import React, { Component } from "react"
import "./form.css"

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validated: false,
      isAlien: false,
    }

    this.validateHuman = this.validateHuman.bind(this)
    this.alienCheck = this.alienCheck.bind(this)
  }

  validateHuman = () => {
    this.setState({
      validated: true,
      isAlien: false,
    })
  }

  alienCheck = () => {
    this.setState({
      isAlien: true,
    })
  }

  render() {
    return (
      <div className="form-box">
        <form
          name={this.props.name}
          method="POST"
          className="form"
          netlify
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <h3 style={{ textAlign: "center" }}>{this.props.title}</h3>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={this.props.name} />
          <p>
            <label>
              <input type="text" name="firstname" placeholder="First Name" />
            </label>
          </p>
          <p>
            <label>
              <input type="text" name="lastname" placeholder="Last Name" />
            </label>
          </p>
          <p>
            <label>
              <input type="email" name="email" placeholder="Email" />
            </label>
          </p>
          {this.props.isNewsletter ? (
            <span></span>
          ) : (
            <p>
              <label>
                <textarea
                  name="message"
                  placeholder="Enter message here"
                  style={{ width: `100%` }}
                ></textarea>
              </label>
            </p>
          )}

          {this.state.validated ? (
            <button type="submit" className="submit-button">
              Send
            </button>
          ) : (
            <div className="validation">
              <p className="validation-p validation-text">
                But first... are you a human?
              </p>
              <div className="validation-responses">
                <p
                  className="validation-p validation-btn"
                  onClick={this.validateHuman}
                >
                  Yes
                </p>
                <p
                  className="validation-p validation-btn"
                  onClick={this.alienCheck}
                >
                  No
                </p>
              </div>
            </div>
          )}
          {this.state.isAlien ? (
            <p className="validation-p">Bots and Aliens aren't welcome..</p>
          ) : (
            <span></span>
          )}
        </form>
      </div>
    )
  }
}

export default Form
