import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Form from "../components/form"
import "./blog-post.css"
import { MetaTags } from "../components/MetaTags"

export default ({ data }) => {
  const post = data.markdownRemark
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout>
      <MetaTags
        title={post.frontmatter.title}
        description={post.frontmatter.intro}
        image={featuredImgFluid.src}
        url="https://www.ashcodes.com"
      />
      <div className="blog-container">
        <div className="blog-content">
          <h1 className="blog-content-title">{post.frontmatter.title}</h1>
          <h3 className="blog-content-intro">{post.frontmatter.intro}</h3>
          <img src={featuredImgFluid.src} className="blog-content-image" />
          <div
            style={{ marginLeft: `left` }}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        <div style={{ textAlign: `center` }}>
          <hr />
          <h3>Thanks for reading through! 🚀</h3>
          <p>
            If you're interested in seeing more of my blogs, please sign up
            below:
          </p>
        </div>
        <Form
          title={"Sign Up For More Blogs"}
          isNewsletter={true}
          name={"Newsletter"}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        intro
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
